































































































import Vue from 'vue';
import axios from 'axios';
import { Column, Config, PagingOptions, SmartTable, Sorting } from 'rey-vue-smarttable';
import Station from '@/models/Station';
import { StationType, StationTypeDropdown } from '@/models/StationType';
import StationParameter from '@/models/StationParameter';
import ParameterType from '@/models/ParameterType';
import ParameterEditor from '@/components/ParameterEditor.vue';
import BufferZones from '@/views/systemadmin/BufferZones.vue';
import LocalizationEditorModal from '@/components/LocalizationEditorModal.vue';
import Guid from 'devextreme/core/guid';
import { reloadActiveLanguage } from '@/languages/i18n';

const LoadState = Object.freeze({ NotStarted: 0, Started: 1, Succeeded: 2, Failed: 3 });
export default Vue.extend({
  name: 'stations',
  components: { LocalizationEditorModal, SmartTable, ParameterEditor, BufferZones },
  data() {
    return {
      ParameterType,
      StationType,
      LoadState,
      selectedStation: undefined as Station | undefined,
      windowPartLoadState: LoadState.NotStarted,
      parameters: undefined as StationParameter[] | undefined,
      smartTableConfig: new Config(
        (row) => row.stationId,
        [
          new Column({
            title: this.$t('views.systemadmin.stations.tableHeaders.id').toString(),
            fieldName: 'stationId',
            fieldType: 'numeric',
            editable: () => false,
            hidden: true,
            hiddenInAddDialog: true
          }),
          new Column({
            title: this.$t('views.systemadmin.stations.tableHeaders.displayName').toString(),
            fieldName: 'displayNameResourceId',
            fieldType: 'string',
            editable: () => false,
            hiddenInAddDialog: true,
            defaultValue: 'Stations.' + new Guid()
          }),
          new Column({
            title: this.$t('views.systemadmin.stations.tableHeaders.icon').toString(),
            fieldName: 'icon',
            fieldType: 'string',
            editable: () => true,
            validator: (newValue) => ({
              isValid: !!newValue && newValue.length <= 50,
              errorMessages: [
                this.$t('validationErrors.invalidTextLength', {
                  currentValue: newValue,
                  maxLength: 50
                }).toString()
              ]
            })
          }),
          new Column({
            title: this.$t('views.systemadmin.stations.tableHeaders.type').toString(),
            fieldName: 'stationType',
            fieldType: 'dropdown',
            editable: () => true,
            dropdownOptions: StationTypeDropdown(),
            defaultValue: StationType.Loading
          }),
          new Column({
            title: this.$t('views.systemadmin.stations.tableHeaders.stationNumber').toString(),
            fieldName: 'stationNumber',
            fieldType: 'numeric',
            editable: () => true,
            validator: (newValue) => ({
              isValid: newValue >= 0 && newValue < 20,
              errorMessages: [
                this.$t('validationErrors.invalidNumberRange', {
                  currentValue: newValue,
                  minValue: 0,
                  maxValue: 20
                }).toString()
              ]
            }),
            defaultValue: 1
          }),
          new Column({
            title: this.$t('views.systemadmin.stations.tableHeaders.stationIndex').toString(),
            fieldName: 'stationIndex',
            fieldType: 'numeric',
            editable: () => true,
            validator: (newValue) => ({
              isValid: newValue >= 0 && newValue < 19,
              errorMessages: [
                this.$t('validationErrors.invalidNumberRange', {
                  currentValue: newValue,
                  minValue: 0,
                  maxValue: 19
                }).toString()
              ]
            }),
            defaultValue: 0
          }),
          new Column({
            title: this.$t('views.systemadmin.stations.tableHeaders.simulation').toString(),
            fieldName: 'isSimulation',
            fieldType: 'boolean',
            editable: () => true
          })
        ],
        new Sorting('displaySortOrder', 'ascending'),
        new PagingOptions(100),
        undefined,
        undefined,
        undefined
      ).withEfApiActions(axios, 'Station'),
      selectedResourceIds: [] as string[]
    };
  },
  watch: {
    selectedStation() {
      this.loadStationParameters();
    }
  },
  methods: {
    showLocalizationModal() {
      // @ts-ignore
      this.$refs.localizationModal.show();
    }, // eslint-disable-next-line
    async onLocalizationUpdatedHandler(smartTable: any) {
      await reloadActiveLanguage();
      smartTable.reload(true);
    },
    async loadStationParameters() {
      this.parameters = undefined;
      await axios
        .post('/api/actions/GetStationParameters/', {
          StationNumber: this.selectedStation?.stationNumber,
          StationType: this.selectedStation?.stationType
        })
        .then((response) => response.data)
        .then((result) => this.updateStationParameters(result));
    },
    updateStationParameters(parameters: StationParameter[]) {
      this.parameters = parameters;
    },
    // eslint-disable-next-line
    async onParameterChanged(parameterDefinitionId: string, newValue: string) {
      await axios
        .post('/api/actions/SetStationParameter/', {
          StationId: this.selectedStation?.stationId,
          ParameterDefinitionId: parameterDefinitionId,
          Value: newValue
        })
        .catch((error) => console.error(error));
    },
    reloadSite() {
      window.location.reload();
    },
    showTranslations(resourceId: string) {
      this.selectedResourceIds = [resourceId];
      // @ts-ignore
      this.showLocalizationModal();
    }
  }
});
