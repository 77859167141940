


















































import Vue from 'vue';
import LocalizationEditor from '@/components/LocalizationEditor.vue';

export default Vue.extend({
  name: 'LocalizationEditorModal',
  components: { LocalizationEditor },
  props: ['resourceIds', 'category'],
  data: () => {
    return {
      showModal: false
    };
  },
  created() {
    document.addEventListener('keyup', (evt) => {
      if (evt.key === 'Escape') {
        this.close();
      }
    });
  },
  methods: {
    show() {
      this.showModal = true;
    },
    close() {
      this.showModal = false;
    }
  }
});
