












































import Vue from 'vue';
import axios from 'axios';
import { Column, Config, Filter, PagingOptions, SmartTable, Sorting } from 'rey-vue-smarttable';
import BufferZone from '@/models/BufferZone';

export default Vue.extend({
  name: 'bufferZones',
  components: { SmartTable },
  props: ['stationId'],
  data() {
    return {
      smartTableConfig: new Config(
        (row) => row.bufferZoneId,
        [
          new Column({
            title: this.$t('views.systemadmin.bufferZones.tableHeaders.id').toString(),
            fieldName: 'bufferZoneId',
            fieldType: 'numeric',
            editable: () => false,
            hidden: true,
            hiddenInAddDialog: true
          }),
          new Column({
            title: 'hidden station-id',
            fieldName: 'stationId',
            fieldType: 'numeric',
            editable: () => false,
            hidden: true,
            hiddenInAddDialog: true,
            defaultValue: this.stationId
          }),
          new Column({
            title: this.$t('views.systemadmin.bufferZones.tableHeaders.displayName').toString(),
            fieldName: 'zoneName',
            fieldType: 'string',
            editable: () => true,
            validator: (newValue) => ({
              isValid: !!newValue && newValue.length <= 50,
              errorMessages: [
                this.$t('validationErrors.invalidTextLength', {
                  currentValue: newValue,
                  maxLength: 50
                }).toString()
              ]
            })
          }),
          new Column({
            title: this.$t('views.systemadmin.bufferZones.tableHeaders.icon').toString(),
            fieldName: 'icon',
            fieldType: 'string',
            editable: () => true,
            validator: (newValue) => ({
              isValid: !!newValue && newValue.length <= 50,
              errorMessages: [
                this.$t('validationErrors.invalidTextLength', {
                  currentValue: newValue,
                  maxLength: 50
                }).toString()
              ]
            })
          }),
          new Column({
            title: this.$t('views.systemadmin.bufferZones.tableHeaders.zoneIndex').toString(),
            fieldName: 'zoneNumber',
            fieldType: 'numeric',
            editable: () => true,
            validator: (newValue) => ({
              isValid: newValue >= 0 && newValue < 100,
              errorMessages: [
                this.$t('validationErrors.invalidNumberRange', {
                  currentValue: newValue,
                  minValue: 0,
                  maxValue: 9
                }).toString()
              ]
            }),
            defaultValue: 0
          }),
          new Column({
            title: this.$t('views.systemadmin.bufferZones.tableHeaders.offset').toString(),
            fieldName: 'offset',
            fieldType: 'numeric',
            editable: () => true,
            validator: (newValue) => ({
              isValid: newValue >= 0 && newValue < 40,
              errorMessages: [
                this.$t('validationErrors.invalidNumberRange', {
                  currentValue: newValue,
                  minValue: 0,
                  maxValue: 40
                }).toString()
              ]
            }),
            defaultValue: 0
          }),
          new Column({
            title: this.$t('views.systemadmin.bufferZones.tableHeaders.numberOfFlybars').toString(),
            fieldName: 'numberOfFlybars',
            fieldType: 'numeric',
            editable: () => true,
            validator: (newValue) => ({
              isValid: newValue >= 0 && newValue < 40,
              errorMessages: [
                this.$t('validationErrors.invalidNumberRange', {
                  currentValue: newValue,
                  minValue: 0,
                  maxValue: 40
                }).toString()
              ]
            }),
            defaultValue: 0
          }),
          new Column({
            title: this.$t('views.systemadmin.bufferZones.tableHeaders.displayWidth').toString(),
            fieldName: 'displayWidth',
            fieldType: 'numeric',
            editable: () => true,
            validator: (newValue) => ({
              isValid: !newValue || (newValue >= 1 && newValue <= 12),
              errorMessages: [
                this.$t('validationErrors.invalidNumberRange', {
                  currentValue: newValue,
                  minValue: 1,
                  maxValue: 12
                }).toString()
              ]
            }),
            defaultValue: 4
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.bufferZones.tableHeaders.fieldDefinitionIds'
            ).toString(),
            fieldName: 'displayFieldDefinitionIds',
            fieldType: 'string',
            editable: () => true,
            validator: (newValue) => ({
              isValid: !newValue || (newValue.startsWith('[') && newValue.endsWith(']')),
              errorMessages: [
                this.$t(
                  'views.systemadmin.bufferZones.validationErrors.fieldDefinitionIds'
                ).toString()
              ]
            }),
            defaultValue: '[]'
          })
        ],
        new Sorting('zoneNumber', 'ascending'),
        new PagingOptions(100),
        undefined,
        undefined,
        [new Filter('StationId', 'numeric', 'equals', [this.stationId])]
      ).withEfApiActions(axios, 'BufferZone'),
      selected: undefined as BufferZone | undefined
    };
  }
});
